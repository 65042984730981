<template>
  <popup-dialog @close="close" v-if="visible">
    <div class="english-lock">
      <div class="english-lock-logo">
        <img src="~@/assets/english_logo.png" />
      </div>
      <div class="english-lock-list">
        <div class="english-lock-list-cell" v-if="report.usd_fact_price && report.usd_fact_price > 0">
          <div class="english-lock-list-cell-header">
            Unlock This Article For ${{ report.usd_fact_price }}
          </div>
          <div class="english-lock-list-cell-content">
            <div class="english-lock-list-cell-content-tip">
              Continue reading with a one-time purchase of this article
            </div>
            <div class="english-lock-list-cell-content-btn" @click="unlock">Unlock</div>
          </div>
        </div>
        <template v-if="userLevel.user_level_details && userLevel.user_level_details.length > 0">
          <div class="english-lock-list-cell" v-for="(item, index) in userLevel.user_level_details" :key="index">
            <div class="english-lock-list-cell-header">
              {{ chineseToEnglish(item.name, true) }} Subscription For ${{ item.usd_fact_price }}
            </div>
            <div class="english-lock-list-cell-content">
              <div class="english-lock-list-cell-content-tip">
                Gain unlimited access to exclusive reports and expert commentaries for a {{ chineseToEnglish(item.name, false) }}
              </div>
              <div class="english-lock-list-cell-content-btn" @click="subscribe(item.id)">Subscribe</div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 选择项目进行支付 -->
    <english-project ref="englishProjectRef"></english-project>
  </popup-dialog>
</template>

<script>
import { memberLevelList } from "@/api";
import EnglishProject from "../../equity/component/englishProject";
import PopupDialog from "@/components/popupDialog";
export default {
  name: "EnglishLock",
  components: {
    EnglishProject,
    PopupDialog
  },
  props: {
    // 报告详情
    report: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      userLevel: {}
    }
  },
  created() {
    this.getMemberLevelList();
  },
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    // 获取会员类型列表
    async getMemberLevelList() {
      this.loading = true;
      let res = await memberLevelList({ search: "id:4|status:1", searchFields: "id:=|status:=", orderBy: "id", sortedBy: "asc" });
      if (res && res.code == 200) {
        if (res.data && res.data.list && res.data.list.length > 0) {
          this.userLevel = res.data.list[0];
        }
      }
      this.loading = false;
    },
    // 将中文转为英文
    chineseToEnglish(str, type) {
      let temp_str = "";
      if (str) {
        temp_str = str[str.length - 1];
        if (temp_str == "年") {
          temp_str = "year";
        } else if (temp_str == "月") {
          temp_str = "month";
        } else if (temp_str == "天") {
          temp_str = "day";
        }
        if (type) {
          return `${str[0]}-${temp_str.replace(/^\w/, letter => letter.toUpperCase())}`
        }
      }
      return temp_str;
    },
    // 解锁当前文章
    unlock() {
      this.$emit("unlock");
    },
    // 订阅
    subscribe(id) {
      let token = localStorage.getItem("cf40-token");
      if (token) {
        this.$refs.englishProjectRef.open(this.userLevel, id);
      } else {
        this.$emit("login");
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.english-lock {
  &-logo {
    max-width: 160px;
  }
  &-list {
    margin-top: 50px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    &-cell {
      width: 30%;
      margin-top: 30px;
      margin-right: 5%;
      display: flex;
      flex-direction: column;
      border: 1px solid #999999;
      &:last-child {
        margin-right: 0;
      }
      &-header {
        font-family: Georgia_Bold;
        font-size: 16px;
        padding: 15px 30px;
        text-align: center;
        background-color: #d9d9d9;
      }
      &-content {
        flex: 1;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &-tip {
          flex: 1;
          font-family: Georgia;
          line-height: 1.5;
          font-size: 14px;
          padding: 30px 0;
        }
        &-btn {
          cursor: pointer;
          user-select: none;
          color: #ffffff;
          text-align: center;
          padding: 10px 30px;
          font-size: 16px;
          font-family: Georgia_Bold;
          background-color: #ed6f33;
          display: inline-block;
          &:hover, &:active {
            background-color: #f28551;
          }
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .english-lock {
    &-list {
      flex-direction: column;
      &-cell {
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
</style>