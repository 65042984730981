<template>
  <div class="popup" :style="{ 'z-index': zIndex }">
    <div class="popup-wrap">
      <div class="popup-wrap-header">
        <span>{{ title }}</span>
        <button type="button" class="popup-wrap-header-close" @click="close">
          <i class="el-icon-close"></i>
        </button>
      </div>
      <div class="popup-wrap-content">
        <slot></slot>
      </div>
    </div>
    <div class="popup-modal"></div>
  </div>
</template>

<script>
export default {
  name: "PopupDialog",
  props: {
    title: {
      type: String,
      default: ""
    },
    zIndex: {
      type: Number,
      default: 1900
    }
  },
  data() {
    return {};
  },
  mounted() {
    window.addEventListener('keyup', this.handleKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyUp);
  },
  methods: {
    close () {
      this.$emit("close");
    },
    handleKeyUp(event) {
      if (event.key === 'Escape') { // 或者使用 event.keyCode === 27
        // 处理ESC按键
        // this.close();
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  &-wrap {
    position: relative;
    width: 900px;
    margin: 15vh auto 50px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .3);
    box-sizing: border-box;
    padding: 30px;
    z-index: 3;
    &-header {
      &-close {
        position: absolute;
        top: 20px;
        right: 20px;
        padding: 0;
        background: transparent;
        outline: none;
        cursor: pointer;
        font-size: 16px;
        width: 25px;
        height: 25px;
        border: 1px solid #909399;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          color: #909399;
        }
        &:hover {
          border-color: #ed6f33;
          i {
            color: #ed6f33;
          }
        }
      }
    }
    &-content {
      margin-top: 30px;
    }
  }
  &-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background: #000;
    z-index: 2;
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .popup {
    &-wrap {
      width: 90%;
      padding: 10px 30px;
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .popup {
    &-wrap {
      width: 90%;
      padding: 10px 30px;
    }
  }
}
</style>