<template>
  <div class="page-404">
    <h1>暂停服务</h1>
    <h3>网站已暂定服务，敬请期待！&nbsp;</h3>
  </div>
</template>

<script>
export default {
  name: 'ErrorStop',
  mounted() {
    document.body.style.backgroundColor = "#eaeaea";
  },
}
</script>
<style lang="scss" scoped>
.page-404 {
  position: absolute;
  height: 250px;
  width: 480px;
  left: 50%;
  top: 50%;
  margin: -125px 0 0 -200px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.0);
  font-size: 14px;
  h1 {
    font-size: 115px;
    font-family: Impact, Arial;
    color: #707070;
    line-height: 100%;
    margin: auto;
    text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.3);
    letter-spacing: 5px;
  }
  h3 {
    font-size: 16px;
    color: #707070;
    line-height: 100%;
    margin: 20px auto 30px;
    font-weight: normal;
    text-indent: 1em;
  }
  .btn {
    display: inline-block;
    color: #707070;
    background: #fff;
    padding: 0 20px;
    height: 36px;
    line-height: 36px;
    width: 100px;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
}
</style>