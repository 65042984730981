<template>
  <div class="chinese-wrap" v-loading="loading">
    <div class="chinese-wrap-title">
      订阅权益
    </div>
    <div class="chinese-wrap-content">
      <div class="chinese-wrap-content-user" v-if="memberInfo.id">
        <div class="name">
          <div class="name-text" v-if="memberInfo.mobile">{{ memberInfo.mobile }}</div>
          <div class="name-text" v-else-if="memberInfo.email">{{ memberInfo.email }}</div>
          <div class="member-tag vip-tag" v-if="memberInfo.vip_level">{{ memberInfo.user_level_name }}</div>
          <div class="member-tag ordinary-tag" v-else>普通会员</div>
        </div>
        <div class="tip" v-if="memberInfo.vip_level">
          <span>会员有效期至：{{ $moment(memberInfo.vip_expire).format('YYYY-MM-DD') }}</span>
        </div>
        <div class="tip" v-else>
          <span>暂未开通VIP会员</span>
        </div>
      </div>
      <div class="chinese-wrap-content-list">
        <div class="chinese-wrap-content-list-cell" v-for="(item, index) in tempTabList" :key="index">
          <div class="chinese-wrap-content-list-cell-title">{{ item.title }}</div>
          <div class="chinese-wrap-content-list-cell-price">
            <span class="unicode">￥</span>
            <span class="money">{{ item.fact_price }}</span>
            <span class="unit">
              /{{ item.unit }}
            </span>
          </div>
          <div class="chinese-wrap-content-list-cell-describe">
            <div class="chinese-wrap-content-list-cell-describe-underline" v-if="item.price && item.fact_price != item.price">
              <span>原价：</span>
              <span class="cost_price">{{ item.price }}</span>
            </div>
            <div class="chinese-wrap-content-list-cell-describe-first_price" v-if="item.first_time_price && item.first_time_price != item.fact_price ">
              <span>首次购买价：</span>
              <span class="cost_price">{{item.first_time_price}}</span>
            </div>
          </div>
          <div class="chinese-wrap-content-list-cell-desc">
            <ul>
              <li v-for="(jtem, jndex) in item.tips" :key="jndex">{{ jtem.text }}</li>
            </ul>
          </div>
          <div class="chinese-wrap-content-list-cell-btn" @click="subscribe(item)">
            订阅
          </div>
          <div class="chinese-wrap-content-list-cell-care" v-if="item.care">{{ item.care }}</div>
        </div>
      </div>
      <div class="chinese-wrap-content-tip">
        <div class="chinese-wrap-content-tip-level">
          <span>机构及高阶会员购买请邮件咨询</span>
          <a :href="'mailto:'+companyEmail" class="link">{{ companyEmail }}</a>
          <span>，或联系销售代表</span>
        </div>
        <div class="chinese-wrap-content-tip-phone">
          <p>
            <span>(华北区)&nbsp;&nbsp;北京代表处：陶女士&nbsp;</span>
            <a href="tel:+8618911263563">+86 18911263563</a>
          </p>
          <p>
            <span>(华东区)&nbsp;&nbsp;上海代表处：张先生&nbsp;</span>
            <a href="tel:+8618565800875">+86 18565800875</a>
          </p>
          <p>
            <span>(华南区)&nbsp;&nbsp;广州代表处：徐女士&nbsp;</span>
            <a href="tel:+8618903012243">+86 18903012243</a>
          </p>
        </div>
      </div>
    </div>
    <!-- 选择项目进行支付 -->
    <chinese-project ref="chineseProjectRef"></chinese-project>
  </div>
</template>

<script>
import { memberLevelList, initConfig, userInfo } from "@/api";
import ChineseProject from "../component/chineseProject";
export default {
  name: "ChineseWrap",
  components: {
    ChineseProject
  },
  data() {
    return {
      loading: false,
      // 默认权益列表
      tabList: [],
      // 模板循环权益列表
      tempTabList: [],
      // 机构对象
      agency: {},
      // 邮箱
      companyEmail: "",
      // 用户信息
      memberInfo: {}
    }
  },
  created() {
    this.getUserInfo();
    this.getInitConfig();
    this.getMemberLevelList();
  },
  methods: {
    // 获取会员类型列表
    async getMemberLevelList() {
      this.loading = true;
      let res = await memberLevelList({ search: "status:1", searchFields: "status:=", orderBy: "id", sortedBy: "asc" });
      if (res && res.code == 200) {
        this.tabList = res.data.list;
        this.levelHandle(res.data.list);
      }
      this.loading = false;
    },
    // 权益数据处理
    levelHandle(rows) {
      if (rows && rows.length > 0) {
        let arr = [];
        for (let i = 0; i < rows.length; i++) {
          let item = rows[i];
          if (item.user_level_details && item.user_level_details.length > 0) {
            for (let j = 0; j < item.user_level_details.length; j++) {
              let jtem = item.user_level_details[j];
              let temps = { title: "", leveId: 0, price: "", fact_price: "", first_time_price: "", unit: "", care: "", tips: [], obj: {} };
              if (item.user_level_name.indexOf('学生') > -1) {
                temps.title = "学生VIP";
                // temps.price = jtem.price;
                temps.fact_price = jtem.fact_price;
                temps.first_time_price = jtem.first_time_price;
                temps.unit = "年";
                temps.leveId = jtem.id;
                temps.care = "*在校学生和两年内高校毕业生可享";
                temps.tips = [
                  { text: "全年100+篇深度研究报告" },
                  { text: "中英双语覆盖" },
                  { text: "赠阅经济类畅销书1本" },
                  { text: "一键畅听报告，浏览独家视频" },
                  { text: "会员专享主题活动等" },
                ];
              } else {
                if (jtem.name.indexOf('月') > -1) {
                  temps.title = "月度VIP";
                  temps.price = jtem.price;
                  temps.fact_price = jtem.fact_price;
                  temps.first_time_price = jtem.first_time_price;
                  temps.unit = "月";
                  temps.leveId = jtem.id;
                  temps.tips = [
                    { text: "查阅往期研究报告" },
                    { text: "中英双语覆盖" },
                    { text: "一键畅听报告，浏览独家视频" },
                  ];
                } else if (jtem.name.indexOf('年') > -1) {
                  temps.title = "年度VIP";
                  temps.price = jtem.price;
                  temps.fact_price = jtem.fact_price;
                  temps.first_time_price = jtem.first_time_price;
                  temps.unit = "年";
                  temps.leveId = jtem.id;
                  temps.tips = [
                    { text: "全年100+篇深度研究报告" },
                    { text: "中英双语覆盖" },
                    { text: "赠阅经济类畅销书2本" },
                    { text: "一键畅听报告，浏览独家视频" },
                    { text: "会员专享主题活动等" },
                  ];
                }
              }
              temps.obj = item;
              arr.push(temps);
            }
          }
        }
        this.tempTabList = arr;
      }
    },
    // 获取配置信息
    async getInitConfig() {
      let res = await initConfig();
      if (res && res.code == 200) {
        if (res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            let item = res.data[i];
            if (item.config_key == "EMAIL") {
              this.companyEmail = item.config_value;
            }
          }
        }
      }
    },
    // 获取用户信息
    async getUserInfo() {
      let token = localStorage.getItem("cf40-token");
      if (token) {
        let res = await userInfo();
        if (res && res.code == 200) {
          this.memberInfo = res.data.user_info;
        }
      }
    },
    // 开始订阅
    subscribe(row) {
      let token = localStorage.getItem("cf40-token");
      if (token) {
        this.$refs.chineseProjectRef.open(row.obj, row.leveId);
      } else {
        this.$emit("login");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chinese-wrap {
  max-width: 1200px;
  margin: 0 auto;
  font-family: HarmonyOS_Regular;
  &-title {
    font-size: 30px;
    text-align: center;
    font-family: HarmonyOS_Bold;
    margin-top: 80px;
  }
  &-content {
    margin-top: 80px;
    min-height: 600px;
    &-user {
      display: flex;
      margin-left: 5%;
      margin-bottom: 20px;
      .name {
        display: flex;
        margin-right: 15px;
      }
      .member-tag {
        font-size: 12px;
        font-weight: 500;
        padding: 3px 10px;
        border-radius: 5px;
        margin-left: 10px;
      }
      .vip-tag {
        color: #ffffff;
        background-color: #ed6f33;
      }
      .ordinary-tag {
        color: #ffffff;
        background-color: #d9d9d9;
      }
    }
    &-list {
      margin: 0 auto;
      display: flex;
      &-cell {
        flex: 1;
        position: relative;
        padding: 30px;
        border: 1px solid #cccccc;
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
        &-title {
          text-align: center;
          font-size: 20px;
          font-family: HarmonyOS_Bold;
        }
        &-price {
          margin-top: 20px;
          display: flex;
          justify-content: center;
          .money {
            font-size: 50px;
            font-family: HarmonyOS_Bold;
          }
          .unicode {
            margin-right: 5px;
            margin-top: 13px;
            display: flex;
            align-items: flex-start;
          }
          .unit {
            margin-left: 5px;
            margin-bottom: 12px;
            display: flex;
            align-items: flex-end;
          }
        }
        &-describe {
          height: 38px;
          font-size: 14px;
          text-align: center;
          &-underline {
            color: #7f7f7f;
            text-decoration: line-through;
          }
          &-first_price {
            color: #ed6f33;
            .cost_price {
              font-family: HarmonyOS_Bold;
            }
          }
        }
        &-desc {
          margin: 20px auto;
          max-width: 230px;
          height: 150px;
          font-size: 14px;
          color: #7f7f7f;
          line-height: 22px;
        }
        &-btn {
          cursor: pointer;
          border-radius: 5px;
          padding: 8px 0;
          bottom: 30px;
          font-family: HarmonyOS_Bold;
          color: #ffffff;
          user-select: none;
          text-align: center;
          background-color: #ed6f33;
          &:hover, &:active {
            background-color: #f38a58;
          }
        }
        &-care {
          margin-top: 10px;
          font-size: 14px;
          text-align: center;
        }
      }
    }
    &-tip {
      font-size: 18px;
      margin-top: 80px;
      a {
        color: #231815;
        text-decoration: none;
        &:hover {
          color: #ed6f33;
        }
      }
      &-level {
        color: #231815;
        text-align: center;
      }
      &-phone {
        font-size: 14px;
        line-height: 26px;
        margin-top: 50px;
        margin-bottom: 30px;
        text-align: center;
        color: #7f7f7f;
        a {
          color: #7f7f7f;
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .chinese-wrap {
    &-title {
      margin-top: 50px;
      font-size: 26px;
    }
    &-content {
      margin-top: 50px;
      &-list {
        padding: 0 30px;
        &-cell {
          &-price {
            .money {
              font-size: 42px;
            }
          }
          &-desc {
            height: 160px;
          }
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .chinese-wrap {
    &-title {
      margin-top: 50px;
      font-size: 26px;
    }
    &-content {
      margin-top: 50px;
      &-user {
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        margin-bottom: 20px;
        .name {
          flex-direction: column;
          align-items: center;
        }
        .member-tag {
          margin-top: 10px;
          font-size: 12px;
          font-weight: 500;
          padding: 3px 10px;
          border-radius: 5px;
          margin-left: 10px;
        }
        .tip {
          margin-top: 10px;
        }
        .vip-tag {
          color: #ffffff;
          background-color: #ed6f33;
          display: inline-block;
        }
        .ordinary-tag {
          color: #ffffff;
          background-color: #d9d9d9;
          display: inline-block;
        }
      }
      &-list {
        width: calc(100% - 60px);
        padding: 0 30px;
        flex-direction: column;
        &-cell {
          margin-top: 30px;
          margin-right: 0;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
</style>