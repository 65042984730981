<template>
  <popup-dialog title="扫码支付" @close="close" v-if="visible">
    <div v-loading="loading">
      <vue-qr
          :logo-src="require('@/assets/cf40.png')"
          :logo-scale="50"
          :text="qr_code"
          :size="350"/>
    </div>
  </popup-dialog>
</template>

<script>
import VueQr from 'vue-qr'
import {createPayQrCode,orderDetail} from "@/api";
import PopupDialog from "@/components/popupDialog";
export default {
  name: "PaymentQrcode",
  components: {
    VueQr,
    PopupDialog
  },
  data() {
    return {
      visible: false,
      // 加载状态
      loading: false,
      // 二位码图片
      qr_code: "",
      // 开始检测
      testing: true,
    }
  },
  methods: {
    // 打开窗口
    open(id) {
      this.testing = true;
      this.pay(id)
      this.timer(id,0);
      this.visible = true;
    },
    timer(id, times){
      setTimeout(async ()=>{
        times++;
        if(times > 100){
          this.close();
          return;
        }
        let token = localStorage.getItem("cf40-token");
        if (token) {
          let res = await orderDetail({id:id})
          if (res && res.code == 200) {
            if (res.data.status == 2){
              this.$message.success("支付成功");
              this.close();
              setTimeout(()=>{location.reload();},3000)
            }else if(res.data.status == 1 && this.testing){
              this.timer(id, times);
            }
          }else{
            this.$message.error(res.msg);
          }
        }
      },1000)
    },
    // 支付
    async pay(id){
      this.loading = true;
      let token = localStorage.getItem("cf40-token");
      if (token) {
        let res = await createPayQrCode({order_id:id});
        if (res && res.code == 200) {
          this.qr_code = res.data.code_url;
        }
      }
      this.loading = false;
    },
    // 关闭窗口
    close() {
      this.testing = false;
      this.visible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .popup-wrap {
    width: 400px !important;
    &-content {
      margin-top: 30px !important;
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  ::v-deep {
    .popup-wrap {
      width: 90% !important;
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  ::v-deep {
    .popup-wrap {
      width: 90% !important;
    }
  }
}
</style>