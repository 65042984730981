<template>
  <popup-dialog title="Subscription" class="english-project" @close="close" v-if="visible">
    <div class="english-project-wrap">
      <div class="members-vip">
        <div class="price-list">
          <div v-for="(item, index) in dataInfo.user_level_details" :class="{'price-list_item-cell': true, 'active': item.id == priceObj.id} " :key="index" @click="choosePrice(item)">
            <div class="month">{{ chineseToEnglish(item.name) }}</div>
            <div class="price">
              <span class="unit">$</span>
              <span v-if="item.usd_first_price">{{item.usd_first_price}}</span>
              <span v-else-if="item.usd_fact_price">{{item.usd_fact_price}}</span>
              <span v-else>{{item.usd_price}}</span>
            </div>
<!--            <div class="const-price" v-if="item.usd_first_price || item.usd_fact_price">-->
<!--              ${{ item.usd_price }}-->
<!--            </div>-->
<!--            <div class="vip-badge" v-if="item.most_buy_count">-->
<!--              HOT-->
<!--            </div>-->
<!--            <div class="vip-badge" v-else-if="item.first_time_price">-->
<!--              New User-->
<!--            </div>-->
          </div>
        </div>
        <el-empty description="No Data" v-if="!dataInfo.user_level_details || dataInfo.user_level_details.length == 0"></el-empty>
      </div>
      <div class="members-remark">
        <div class="accord">
          <span>By proceeding with payment, you confirm that you have read and agreed to the CF40 Research </span>
          <span class="text-link" @click="openProtocol('','MEMBERSHIP_SERVICE_AGREEMENT_EN')">Membership Service Agreement</span>
          <span> and </span>
          <span class="text-link" @click="openProtocol('','PRIVACY_CLAUSE_EN')">Privacy Policy</span>
          <span>.</span>
        </div>
      </div>
      <div class="opera-submit" v-if="dataInfo.type == 1">
        <div class="opera-submit_total">
          <span>Total:</span>
          <span class="price">$&nbsp;{{ payPrice }}</span>
        </div>
        <el-button type="danger" :loading="payLoading" @click="createOrder" size="medium" :disabled="dataInfo.examine == 1 && !dataInfo.is_examine" round>Continue with your payment</el-button>
      </div>
      <!-- 会员服务协议 -->
      <service-agreement ref="serviceAgreementRef"></service-agreement>
    </div>
  </popup-dialog>
</template>

<script>
import PopupDialog from "@/components/popupDialog";
import ServiceAgreement from "@/components/serviceAgreement";
import { createVipOrder, payPalDefray, initConfig } from "@/api"
export default {
  name: "EnglishProject",
  components: {
    PopupDialog,
    ServiceAgreement
  },
  data() {
    return {
      // 窗口是否显示
      visible: false,
      // 数据
      dataInfo: {},
      // 选中的价格信息
      priceObj: {},
      payLoading: false,
    }
  },
  computed: {
    // 支付金额
    payPrice() {
      if (this.priceObj && this.priceObj.usd_first_price) {
        return this.priceObj.usd_first_price;
      } else if (this.priceObj && this.priceObj.usd_fact_price) {
        return this.priceObj.usd_fact_price;
      } else if (this.priceObj && this.priceObj.usd_price) {
        return this.priceObj.usd_price;
      } else {
        return false;
      }
    },
  },
  methods: {
    // 打开窗口
    open(row, selectLevelId) {
      if (row.id) {
        this.initPrice(row.user_level_details, selectLevelId);
        this.dataInfo = row;
        this.visible = true;
      }
    },
    // 关闭窗口
    close () {
      this.visible = false;
    },
    // 初始化选中价格
    initPrice(rows, levelId) {
      if (rows && rows.length > 0) {
        let temp_index = 0;
        for (let i = 0; i < rows.length; i++) {
          let item = rows[i];
          if (levelId && levelId > 0) {
            if (item.id == levelId) {
              temp_index = i;
              break;
            }
          } else if (item.most_buy_count) {
            temp_index = i;
            break;
          }
        }
        this.priceObj = rows[temp_index];
      }
    },
    // 打开会员协议
    async openProtocol(name, key) {
      let res = await initConfig();
      if (res && res.code == 200) {
        if (res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            let item = res.data[i];
            if (item.config_key == key) {
              this.$refs.serviceAgreementRef.open(name, item.config_value);
              break;
            }
          }
        }
      }
    },
    // 选择价格
    choosePrice(e) {
      this.priceObj = e;
    },
    // 讲中文转为英文
    chineseToEnglish(str) {
      let temp_str = "";
      if (str) {
        if (str.match(/\d+/g) && str.match(/\d+/g).length > 0) {
          temp_str = str.match(/\d+/g)[0];
        }
        if (str[str.length - 1] == "年") {
          temp_str = `${temp_str} Year`;
        } else if (str[str.length - 1] == "月") {
          temp_str = `${temp_str} Month`;
        } else if (str[str.length - 1] == "天") {
          temp_str = `${temp_str} Day`;
        }
      }
      return temp_str;
    },
    // 获取返回链接
    getBackUrl() {
      return `${window.location.protocol}//${window.location.host}${window.location.pathname}?retracement=success`;
    },
    // paypal支付
    async payPalHandle(orderId) {
      this.payLoading = true;
      let res = await payPalDefray({ order_id: orderId, return_url: this.getBackUrl() }, true);
      if (res && res.code == 200) {
        if (res.data && res.data.response && res.data.response.links && res.data.response.links.length > 0) {
          let payUrl = res.data.response.links[res.data.response.links.length - 1]['href'];
          if (payUrl) {
            window.location.href = payUrl;
          }
        }
      }
      this.payLoading = false;
    },
    //下单
    async createOrder() {
      if (this.priceObj){
        let token = localStorage.getItem("cf40-token");
        if (token) {
          let res = await createVipOrder({user_level_detail_id:this.priceObj.id}, true);
          if (res && res.code == 200) {
            this.payPalHandle(res.data.order.id);
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.english-project {
  font-family: Georgia;
  ::v-deep {
    .popup-wrap {
      width: 600px;
      &-content {
        margin-top: 0;
      }
    }
  }
  .members-vip {
    .price-list {
      display: flex;
      flex-wrap: wrap;
      &_item-cell {
        margin-top: 25px;
        width: 121px;
        min-width: 75px;
        position: relative;
        padding: 15px 15px;
        border-radius: 10px;
        background-color: #f3f3f3;
        border: 3px solid #f3f3f3;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 15px;
        cursor: pointer;
        z-index: 1;
        .month {
          font-size: 16px;
          color: #000000;
        }
        .price {
          color: #000000;
          font-size: 28px;
          font-family: "Georgia_Bold";
          padding: 12px 0;
          display: flex;
          .unit {
            font-size: 14px;
            color: #7c7b7b;
            font-weight: 500;
            padding-top: 4px;
            padding-right: 3px;
          }
        }
        .const-price {
          font-size: 16px;
          color: #8c8b8b;
          text-decoration: line-through;
        }
        &:last-child {
          margin-right: 0;
        }
        &.active {
          background-color: #fae9e1;
          border: 3px solid #ed6f33;
        }
        .vip-badge {
          position: absolute;
          top: -15px;
          left: -3px;
          color: #ffffff;
          font-size: 12px;
          padding: 5px 10px;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          border-bottom-right-radius: 8px;
          background-color: #ed6f33;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 30;
        }
      }
    }
    .desc {
      padding-top: 15px;
      text-align: center;
      font-size: 14px;
      color: #a8a8a8;
    }
  }
  .members-remark {
    .accord {
      margin-top: 20px;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
      .text-link {
        color: #ed6f33;
        user-select: none;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .opera-submit {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_tips {
      width: 100%;
      font-size: 14px;
      color: #dd7643;
      font-family: "Georgia_Bold";
      display: flex;
      justify-content: flex-end;
    }
    &_total {
      display: flex;
      align-items: center;
      .price {
        color: #dd7643;
        font-size: 18px;
        padding-left: 5px;
        font-family: "Georgia_Bold";
      }
    }
    ::v-deep {
      .el-button--danger {
        background-color: #ed6f33;
        border-color: #ed6f33;
        &:hover, &:active {
          background-color: #f7844d;
          border-color: #f7844d;
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .english-project {
    ::v-deep {
      .popup-wrap {
        width: 90%;
        &-content {
          margin-top: 0;
        }
      }
    }
    &-wrap {
      .members-vip {
        .price-list {
          &_item-cell {
            width: auto;
          }
        }
      }
      .opera-submit {
        &_total {
          .price {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>