import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ZhCnLocal from "element-ui/lib/locale/lang/zh-CN";

import 'swiper/css/swiper.min.css';

Vue.use(ElementUI, {
  size: 'small',
  locale: ZhCnLocal
});

// moment 时间处理
import moment from "moment";

import { loadExternalScript, routerLink, isNumber, setPageSeo, handleTree, createYears } from "@/utils/tool";
// 全局方法挂载
Vue.prototype.loadExternalScript = loadExternalScript;
Vue.prototype.routerLink = routerLink;
Vue.prototype.isNumber = isNumber;
Vue.prototype.setPageSeo = setPageSeo;
Vue.prototype.handleTree = handleTree;
Vue.prototype.createYears = createYears;

// 全局方法挂载
Vue.prototype.$moment = moment


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')


