<template>
  <div :class="{ 'web-footer': true, 'en-font': language == 'en', 'cn-font': language == 'cn' }">
    <div class="web-footer-box" v-if="language == 'cn'">
      <div class="web-footer-box-list">
        <div class="web-footer-box-list-cell">
          <span>Tel/联系电话：</span>
          <span>{{ configObj.CONNECT_MOBILE }}</span>
        </div>
        <div class="web-footer-box-list-cell">
          <span>Address/地址：</span>
          <span>{{ configObj.ADDRESS }}</span>
        </div>
        <div class="web-footer-box-list-cell">
          <span>Mail/邮箱：</span>
          <span>{{ configObj.EMAIL }}</span>
        </div>
      </div>
      <div class="copy-right">
        <span v-if="configObj.BEIAN">© 2023 <a href="http://www.beian.miit.gov.cn/" target="_blank">{{ configObj.BEIAN }}</a></span>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;欢迎您访问北京四十人信息科技有限公司</span>
      </div>
    </div>
    <div class="web-footer-box" v-else>
      <div class="web-footer-box-list">
        <div class="web-footer-box-list-cell">
          <span>Tel：</span>
          <span>{{ configObj.CONNECT_MOBILE }}</span>
        </div>
        <div class="web-footer-box-list-cell">
          <span>Address：</span>
          <span>{{ configObj.ADDRESS }}</span>
        </div>
        <div class="web-footer-box-list-cell">
          <span>Mail：</span>
          <span>{{ configObj.EMAIL }}</span>
        </div>
      </div>
      <div class="copy-right">
        <span>Copyright © CHINA FINANCE 40 FORUM 2009-2024</span>
      </div>
    </div>
  </div>
</template>

<script>
import { initConfig } from "@/api";
export default {
  name: "WebFooter",
  props: {
    language: {
      type: String,
      default: "en"
    }
  },
  data() {
    return {
      // 配置信息
      configObj: {
        CONNECT_MOBILE: "",
        ADDRESS: "",
        EMAIL: "",
        BEIAN: "",
        PCLOGO: ""
      },
    };
  },
  created() {
    this.getInitCofing();
  },
  methods: {
    // 获取配置信息
    async getInitCofing() {
      let _This = this;
      let res = await initConfig();
      if (res && res.code == 200) {
        this.allConfig = res.data;
        if (res.data.length > 0) {
          let temp = {};
          for (let i = 0; i < res.data.length; i++) {
            let item = res.data[i];
            if (item.config_key == "CONNECT_MOBILE") {
              temp['CONNECT_MOBILE'] = item.config_value;
            } else if (item.config_key == "ADDRESS") {
              temp['ADDRESS'] = item.config_value;
            } else if (item.config_key == "EMAIL") {
              temp['EMAIL'] = item.config_value;
            } else if (item.config_key == "BEIAN") {
              temp['BEIAN'] = item.config_value;
            } else if (item.config_key == "PCLOGO") {
              temp['PCLOGO'] = item.config_value;
            }
          }
          _This.configObj = temp;
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.en-font {
  font-family: Georgia_Bold;
}
.cn-font {
  font-family: HarmonyOS_Regular;
}
.web-footer {
  padding: 10px 0;
  background-color: #231815;
  &-box {
    width: 1200px;
    margin: 0 auto;
    padding: 10px 0;
    color: #ffffff;
    font-size: 14px;
    &-list {
      display: flex;
      justify-content: center;
      &-cell {
        margin-left: 15px;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    .copy-right {
      padding-top: 10px;
      text-align: center;
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .web-footer {
    &-box {
      width: calc(100% - 60px);
      padding: 0 30px;
      &-list {
        flex-wrap: wrap;
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .web-footer {
    &-box {
      width: calc(100% - 60px);
      padding: 0 30px;
      &-list {
        justify-content: left;
        flex-direction: column;
        flex-wrap: wrap;
        &-cell {
          margin-left: 0;
        }
      }
    }
  }
}
</style>