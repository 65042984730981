import request from '@/utils/request'

/**
 * PC-小程序登陆码
 * @returns
 */
export async function appletQR (param, lang) {
    return request({
        url: `/pc/api/qrcode${ lang ? '?lang=en' : '' }`,
        method: 'get',
        params: param
    });
}
/**
 * 检测是否登录
 * @returns
 */
export async function testLogin (param, lang) {
    return request({
        url: `/pc/api/checkLogin${ lang ? '?lang=en' : '' }`,
        method: 'get',
        params: param
    });
}
/**
 * 研究报告-分类列表
 * @returns
 */
export async function classifyList (param, lang) {
    return request({
        url: `/pc/api/main/classes${ lang ? '?lang=en' : '' }`,
        method: 'get',
        params: param
    });
}
/**
 * 研究报告-列表
 * @returns
 */
export async function reportList (param, lang) {
    return request({
        url: `/pc/api/main/products${ lang ? '?lang=en' : '' }`,
        method: 'get',
        params: param
    });
}
/**
 * 研究报告-详情
 * @returns
 */
export async function subjectList (param, lang) {
    return request({
        url: `/pc/api/main/specialSubjects${ lang ? '?lang=en' : '' }`,
        method: 'get',
        params: param
    });
}
/**
 * 研究报告-详情
 * @returns
 */
export async function reportDetail (param, lang) {
    return request({
        url: `/pc/api/main/product/detail${ lang ? '?lang=en' : '' }`,
        method: 'get',
        params: param
    });
}
/**
 * 获取单页
 * @returns
 */
export async function singleDetail (param, lang) {
    return request({
        url: `/pc/api/main/news/detail${ lang ? '?lang=en' : '' }`,
        method: 'get',
        params: param
    });
}
/**
 * 获取单页列表
 * @returns
 */
export async function singleNewList (param, lang) {
    return request({
        url: `/pc/api/main/news${ lang ? '?lang=en' : '' }`,
        method: 'get',
        params: param
    });
}
/**
 * 创建VIP订单
 * @returns
 */
export async function createVipOrder (param, lang) {
    return request({
        url: `/pc/api/main/product/vipOrderCreate${ lang ? '?lang=en' : '' }`,
        method: 'post',
        data: param
    });
}
/**
 * 创建订单
 * @returns
 */
export async function createOrder (param, lang) {
    return request({
        url: `/pc/api/main/product/createOrder${ lang ? '?lang=en' : '' }`,
        method: 'post',
        data: param
    });
}
/**
 * PayPal支付
 * @returns
 */
export async function payPalDefray (param, lang) {
    return request({
        url: `/pc/api/main/paypal${ lang ? '?lang=en' : '' }`,
        method: 'post',
        data: param
    });
}
/**
 * PayPal支付后检测
 * @returns
 */
export async function payPalCheck (param, lang) {
    return request({
        url: `/pc/api/main/product/paypalPayCheckOrder${ lang ? '?lang=en' : '' }`,
        method: 'get',
        params: param
    });
}
/**
 * 订单详情
 * @returns
 */
export async function orderDetail (param, lang) {
    return request({
        url: `/pc/api/main/product/orderInfo${ lang ? '?lang=en' : '' }`,
        method: 'get',
        params: param
    });
}
/**
 * 生成支付二维码
 * @returns
 */
export async function createPayQrCode (param, lang) {
    return request({
        url: `/pc/api/main/pay${ lang ? '?lang=en' : '' }`,
        method: 'post',
        data: param
    });
}
/**
 * 获取会员类型列表
 * @returns
 */
export async function memberLevelList (param, lang) {
    return request({
        url: `/pc/api/main/userLevelLists${ lang ? '?lang=en' : '' }`,
        method: 'get',
        params: param
    });
}
/**
 * 查询会员学生资质审核信息
 * @returns
 */
export async function getVipExamine (param, lang) {
    return request({
        url: `/pc/api/main/studentExamine${ lang ? '?lang=en' : '' }`,
        method: 'get',
        params: param
    });
}
/**
 * 获取区域
 * @returns
 */
export async function regionList (lang) {
    return request({
        url: `/areas${ lang ? '?lang=en' : '' }`,
        method: 'get'
    });
}
/**
 * 学生资质提交
 * @returns
 */
export async function vipExamine (param, lang) {
    return request({
        url: `/pc/api/main/applyForStudent${ lang ? '?lang=en' : '' }`,
        method: 'post',
        data: param
    });
}
/**
 * 获取配置信息-备案号、电话、邮箱、logo
 * @returns
 */
export async function initConfig (param, lang) {
    return request({
        url: `/pc/api/main/init${ lang ? '?lang=en' : '' }`,
        method: 'get',
        params: param
    });
}
/**
 * 登录成功后获取用户信息
 * @returns
 */
export async function userInfo (param, lang) {
    return request({
        url: `/pc/api/main/user/infos${ lang ? '?lang=en' : '' }`,
        method: 'get',
        params: param
    });
}
/**
 * 获取PDF文件下载数据
 * @returns
 */
export async function reportPdf (param, lang) {
    return request({
        url: `/pc/api/main/product/privatePreView${ lang ? '?lang=en' : '' }`,
        method: 'get',
        responseType: 'blob',
        params: param
    });
}
/**
 * 登录系统
 * @returns
 */
export async function loginSystem (param, lang) {
    return request({
        url: `/pc/api/login${ lang ? '?lang=en' : '' }`,
        method: 'post',
        data: param
    });
}
/**
 * 退出系统
 * @returns
 */
export async function logoutSystem (lang) {
    return request({
        url: `/pc/api/main/user/logout${ lang ? '?lang=en' : '' }`,
        method: 'get'
    });
}
/**
 * 注册系统
 * @returns
 */
export async function registerSystem (param, lang) {
    return request({
        url: `/pc/api/email/register${ lang ? '?lang=en' : '' }`,
        method: 'post',
        data: param
    });
}
/**
 * 发送注册验证码
 * @returns
 */
export async function sendRegisterCode (param, lang) {
    return request({
        url: `/pc/api/email/send_register_email_code${ lang ? '?lang=en' : '' }`,
        method: 'post',
        data: param
    });
}
/**
 * 重置密码
 * @returns
 */
export async function forgotPassword (param, lang) {
    return request({
        url: `/pc/api/email/reset_password${ lang ? '?lang=en' : '' }`,
        method: 'post',
        data: param
    });
}
/**
 * 发送重置密码验证码
 * @returns
 */
export async function sendForgotCode (param, lang) {
    return request({
        url: `/pc/api/email/send_reset_password_email_code${ lang ? '?lang=en' : '' }`,
        method: 'post',
        data: param
    });
}