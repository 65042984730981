<template>
  <popup-dialog :class="{ 'pay-wechat': true, 'en-font': language == 'en', 'cn-font': language == 'cn' }" @close="close" v-if="visible">
    <div class="pay-wechat-tab" v-if="language == 'cn'">
      <div :class="{ 'pay-wechat-tab-cell': true, 'active': mode == 1 }" @click="changeMode(1)">微信支付</div>
      <div :class="{ 'pay-wechat-tab-cell': true, 'active': mode == 2 }" @click="changeMode(2)">PayPal支付</div>
    </div>
    <div class="pay-wechat-wx" v-loading="loading" v-if="mode == 1">
      <vue-qr
          :logo-src="require('@/assets/cf40.png')"
          :logo-scale="50"
          :text="qr_code"
          :size="350"/>
      <div style="text-align: center" v-if="orderInfo.pay_amount">
        <div>本文为付费内容，</div>
        <div>请扫码支付({{ orderInfo.pay_amount }}元/篇)或成为订阅会员后登录查看</div>
      </div>
      <div class="pay-wechat-wx-tip">
        <span>请仔细阅读CF40研究</span>
        <span class="text-link" @click="openProtocol('','MEMBERSHIP_SERVICE_AGREEMENT')">《会员服务协议》</span>
        <span class="text-link" @click="openProtocol('','PRIVACY_CLAUSE')">《隐私条款》</span>
        <span>，支付即表示同意</span>
      </div>
    </div>
    <div class="pay-wechat-paypal" v-else>
      <template v-if="orderInfo.usd_pay_amount">
        <div class="pay-wechat-paypal-text">
          <div>{{ language == 'cn' ? '本文为付费内容，' : 'This article is a paid content,' }}</div>
          <div v-if="language == 'cn'">
            请支付({{ orderInfo.usd_pay_amount }}美元/篇)或成为
            <span :class="{ 'link': true, 'en-font-bold': language == 'en', 'cn-font-bold': language == 'cn' }" @click="subscribe">订阅</span>
            会员后查看
          </div>
          <div v-else>
            Please pay ${{ orderInfo.usd_pay_amount }} or
            <span :class="{ 'link': true, 'en-font-bold': language == 'en', 'cn-font-bold': language == 'cn' }" @click="subscribe">Subscribe</span>
            to become a member to view
          </div>
        </div>
        <div class="pay-wechat-paypal-tip">
          <div class="accord" v-if="language == 'cn'">
            <span>请仔细阅读CF40研究</span>
            <span class="text-link" @click="openProtocol('','MEMBERSHIP_SERVICE_AGREEMENT')">《会员服务协议》</span>
            <span class="text-link" @click="openProtocol('','PRIVACY_CLAUSE')">《隐私条款》</span>
            <span>，支付即表示同意</span>
          </div>
          <div class="accord" v-if="language == 'en'">
            <span>By proceeding with payment, you confirm that you have read and agreed to the CF40 Research </span>
            <span class="text-link" @click="openProtocol('','MEMBERSHIP_SERVICE_AGREEMENT_EN')">Membership Service Agreement</span>
            <span> and </span>
            <span class="text-link" @click="openProtocol('','PRIVACY_CLAUSE_EN')">Privacy Policy</span>
            <span>.</span>
          </div>
        </div>
        <div class="pay-wechat-paypal-btn">
          <el-button type="danger" :loading="payLoading" @click="payPalCn" size="medium" v-if="language == 'cn'">确认支付</el-button>
          <el-button type="danger" :loading="payLoading" @click="payPalEn" size="medium" v-else>Continue with your payment</el-button>
        </div>
      </template>
      <template v-else>
        <el-empty description="暂无PayPal价格设置，请联系客服" v-if="language == 'cn'"></el-empty>
        <el-empty description="PayPal price settings are currently unavailable. Please contact customer service" v-else-if="language == 'en'"></el-empty>
      </template>
    </div>
    <!-- 会员服务协议 -->
    <service-agreement ref="serviceAgreementRef"></service-agreement>
  </popup-dialog >
</template>

<script>
import { createOrder, createPayQrCode, orderDetail, payPalDefray, initConfig } from "@/api";
import VueQr from 'vue-qr'
import PopupDialog from "@/components/popupDialog";
import ServiceAgreement from "@/components/serviceAgreement";
export default {
  name: "PayWechat",
  components: {
    VueQr,
    PopupDialog,
    ServiceAgreement
  },
  props: {
    language: {
      type: String,
      default: "cn"
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      // 报告ID
      reportId: null,
      // 订单对象
      orderInfo: {},
      // 订单是否支付完成轮询定时器
      orderTimer: null,
      // 支付金额
      price: "",
      // 支付参数
      qr_code: "",
      // 支付方式 1 微信支付  2 PayPal支付
      mode: 2,
      // 支付状态
      payLoading: false
    }
  },
  methods: {
    // 打开窗口
    open(productId) {
      if (productId) {
        this.reportId = productId;
        this.buildOrder(productId);
        if (this.language == 'en') {
          this.mode = 2;
        } else {
          this.mode = 1;
        }
        this.visible = true;
      } else {
        this.$message.error(this.language == "cn" ? "您选择的文件不存在，请重试" : "The file you selected does not exist, please try again");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    },
    // 关闭窗口
    close () {
      clearInterval(this.orderTimer);
      this.visible = false;
    },
    // 打开会员协议
    async openProtocol(name, key) {
      let res = await initConfig();
      if (res && res.code == 200) {
        if (res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            let item = res.data[i];
            if (item.config_key == key) {
              this.$refs.serviceAgreementRef.open(name, item.config_value);
              break;
            }
          }
        }
      }
    },
    // 创建订单
    async buildOrder(productId) {
      let res = await createOrder({ product_id: productId }, this.language == 'en');
      if (res && res.code == 200) {
        if (res.data && res.data.order && res.data.order.id) {
          // if (this.language == 'en') {
          //   this.price = res.data.order.usd_pay_amount;
          // } else {
          //   this.price = res.data.order.pay_amount;
          // }
          this.orderInfo = res.data.order;
          if (this.mode == 1) {
            this.buildPayQrCode(res.data.order.id);
          }
        }
      }
    },
    // 创建支付
    async buildPayQrCode(orderId) {
      let _This = this;
      this.loading = true;
      let res = await createPayQrCode({ order_id: orderId }, this.language == 'en');
      if (res && res.code == 200) {
        this.qr_code = res.data.code_url;
        this.orderTimer = setInterval(function() {
          _This.payments();
        }, 1000);
      }
      this.loading = false;
    },
    // 验证是否支付完成
    async payments() {
      let res = await orderDetail({ id: this.orderInfo.id }, this.language == 'en');
      if (res && res.code == 200) {
        if (res.data && res.data.status == 2) {
          this.close();
          this.$message.success(this.language == 'en' ? 'Payment Successful' : '支付成功');
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      }
    },
    // 切换支付模式
    changeMode(e) {
      if (e == 2) {
        clearInterval(this.orderTimer);
      } else {
        this.buildOrder(this.reportId);
      }
      this.mode = e;
    },
    // 跳转到订阅页面
    subscribe() {
      if (this.language == "cn") {
        this.routerLink('/equity/chinese', true);
      } else {
        this.routerLink('/equity/english', true);
      }
    },
    // 获取返回链接
    getBackUrl() {
      return `${window.location.protocol}//${window.location.host}${window.location.pathname}?retracement=success`;
    },
    // 中文支付
    async payPalCn() {
      this.payLoading = true;
      let res = await payPalDefray({ order_id: this.orderInfo.id, return_url: this.getBackUrl() });
      if (res && res.code == 200) {
        if (res.data && res.data.response && res.data.response.links && res.data.response.links.length > 0) {
          let payUrl = res.data.response.links[res.data.response.links.length - 1]['href'];
          if (payUrl) {
            window.location.href = payUrl;
            // this.routerLink(payUrl, true);
            // this.$alert(`请在新打开的页面进行充值，支付成功后刷新当前页面。<br/>PayPal支付可能有延迟，如您支付已完成，刷新页面无变化，请等待1分钟后重试`, '温馨提示', {
            //   dangerouslyUseHTMLString: true,
            //   confirmButtonText: '支付成功',
            //   confirmButtonClass: "confirm-button",
            //   callback: (action) => {
            //     if (action == "confirm") {
            //       window.location.reload();
            //     }
            //   }
            // });
          }
        }
      }
      this.payLoading = false;
    },
    // 英文支付
    async payPalEn() {
      this.payLoading = true;
      let res = await payPalDefray({ order_id: this.orderInfo.id, return_url: this.getBackUrl() }, true);
      if (res && res.code == 200) {
        if (res.data && res.data.response && res.data.response.links && res.data.response.links.length > 0) {
          let payUrl = res.data.response.links[res.data.response.links.length - 1]['href'];
          if (payUrl) {
            window.location.href = payUrl;
            // this.routerLink(payUrl, true);
            // this.$alert(`<p style="font-family: Georgia;">
            //                         Please recharge on the newly opened page and refresh the current page after successful payment.
            //                         <br/>PayPal payment may be delayed. If your payment has been completed and there are no changes when refreshing the page, please wait for 1 minute and try again</p>`,
            //     `Reminder`, {
            //       dangerouslyUseHTMLString: true,
            //       confirmButtonText: 'Payment Successful',
            //       confirmButtonClass: "confirm-button",
            //       callback: (action) => {
            //         if (action == "confirm") {
            //           window.location.reload();
            //         }
            //       }
            //     });
          }
        }
      }
      this.payLoading = false;
    }
  }
}
</script>


<style lang="scss" scoped>
.en-font {
  font-family: Georgia;
}
.en-font-bold {
  font-family: Georgia_Bold;
}
.cn-font {
  font-family: HarmonyOS_Regular;
}
.cn-font-bold {
  font-family: HarmonyOS_Bold;
}
.pay-wechat {
  ::v-deep {
    .popup-wrap {
      width: 460px;
      &-content {
        margin-top: 30px !important;
      }
    }
  }
  &-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    &-cell {
      position: relative;
      margin-right: 30px;
      cursor: pointer;
      padding-bottom: 10px;
      &:hover,&.active {
        font-family: HarmonyOS_Bold;
        color: #ed6f33;
        &:after {
          content: "";
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 100%;
          border-bottom: 1px solid #ed6f33;
        }
      }
    }
  }
  &-wx {
    text-align: center;
    &-tip {
      margin-top: 10px;
      font-size: 14px;
      color: #000000;
      .text-link {
        color: #ed6f33;
        user-select: none;
        cursor: pointer;
      }
    }
  }
  &-paypal {
    margin-top: 15px;
    &-text {
      text-align: center;
      line-height: 26px;
      .link {
        cursor: pointer;
        color: #ed6f33;
      }
    }
    &-tip {
      .accord {
        margin-top: 10px;
        font-size: 14px;
        color: #000000;
        .text-link {
          color: #ed6f33;
          user-select: none;
          cursor: pointer;
        }
      }
    }
    &-btn {
      margin-top: 20px;
      text-align: center;
      ::v-deep {
        .el-button--danger {
          background-color: #ed6f33;
          border-color: #ed6f33;
          &:hover, &:active {
            background-color: #f7844d;
            border-color: #f7844d;
          }
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pay-wechat {
    ::v-deep {
      .popup-wrap {
        width: 90% !important;
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .pay-wechat {
    ::v-deep {
      .popup-wrap {
        width: 90% !important;
      }
    }
  }
}
</style>