<template>
  <popup-dialog @close="close" :z-index="2000" v-if="visible">
    <div class="login-english">
      <div class="login-english-logo">
        <img src="~@/assets/english_logo.png" alt="CF40 RESEARCH" />
      </div>
      <div class="login-english-form">
        <el-form ref="subForm" :model="subForm" :rules="rules" label-width="150px">
          <el-form-item label="Account" prop="username" v-if="type == 'login'">
            <el-input v-model="subForm.username" placeholder="Please enter your account" @keyup.native.enter="operate" clearable></el-input>
          </el-form-item>
          <el-form-item label="Email" prop="email" v-if="type == 'register' || type == 'forgot'">
            <el-input v-model="subForm.email" placeholder="Please enter your email" @keyup.native.enter="operate" clearable></el-input>
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input v-model="subForm.password" type="password" placeholder="Please enter your password" @keyup.native.enter="operate" clearable></el-input>
          </el-form-item>
          <el-form-item label="Confirm Password" prop="checkPass" v-if="type == 'register' || type == 'forgot'">
            <el-input v-model="subForm.checkPass" type="password" placeholder="Please enter your password again" @keyup.native.enter="operate" clearable></el-input>
          </el-form-item>
          <el-form-item label="Code" prop="code" v-if="type == 'register' || type == 'forgot'">
            <el-input v-model="subForm.code" placeholder="Please enter your code" @keyup.native.enter="operate" clearable>
              <el-button slot="append" :loading="codeLoading" :disabled="countdown > 0" @click="getVeriCode">
                {{ countdown > 0 ? `${countdown} Seconds Again` : 'Get Code' }}
              </el-button>
            </el-input>
          </el-form-item>
          <template v-if="type == 'register'">
            <el-form-item>
              <div class="tip-accord">
                <span>By proceeding with registration, you confirm that you have read and agreed to the CF40 Research </span>
                <span class="text-link" @click="openProtocol('','MEMBERSHIP_SERVICE_AGREEMENT_EN')">Membership Service Agreement</span>
                <span> and </span>
                <span class="text-link" @click="openProtocol('','PRIVACY_CLAUSE_EN')">Privacy Policy</span>
                <span>.</span>
              </div>
            </el-form-item>
          </template>
          <el-form-item>
            <el-button class="submit" :loading="loading" @click="operate">
              <span v-if="type == 'login'">Sign In</span>
              <span v-else-if="type == 'register'">Register</span>
              <span v-else-if="type == 'forgot'">Reset Password</span>
            </el-button>
          </el-form-item>
          <template v-if="type == 'login'">
            <el-form-item>
              <div class="flex-row">
                <div class="forgot-pass" @click="typeChange('forgot')">
                  Forgot your password?
                </div>
                <div class="register" @click="typeChange('register')">
                  Register
                </div>
              </div>
            </el-form-item>
          </template>
          <template v-else-if="type == 'register'">
            <el-form-item>
              <div class="flex-row">
                <div class="forgot-pass" @click="typeChange('forgot')">
                  Already have an account?
                </div>
                <div class="register" @click="typeChange('login')">
                  Sign In
                </div>
              </div>
            </el-form-item>
          </template>
          <template v-else-if="type == 'forgot'">
            <el-form-item>
              <div class="flex-row">
                <div class="forgot-pass"></div>
                <div class="register" @click="typeChange('login')">
                  Sign In
                </div>
              </div>
            </el-form-item>
          </template>
        </el-form>
      </div>
    </div>
    <!-- 会员服务协议 -->
    <service-agreement ref="serviceAgreementRef"></service-agreement>
  </popup-dialog>
</template>

<script>
import PopupDialog from "../popupDialog";
import ServiceAgreement from "@/components/serviceAgreement";
import { loginSystem, registerSystem, sendRegisterCode, forgotPassword, sendForgotCode, initConfig } from "@/api";
export default {
  name: "LoginEnglish",
  components: {
    PopupDialog,
    ServiceAgreement
  },
  data() {
    return {
      visible: false,
      loading: false,
      codeLoading: false,
      type: "login",
      subForm: {},
      // 倒计时描述
      countdown: 0,
      // 验证码计时器
      codeTimer: null,
      rules: {
        username: [
          { required: true, message: "Please enter your account", trigger: "blur" },
        ],
        email: [
          { required: true, message: "Please enter your email", trigger: "blur" },
          { type: "email", message: "Please enter the correct email format", trigger: ["blur", "change"] }
        ],
        password: [
          { required: true, message: "Please enter your password", trigger: "blur" },
          { min: 6, max: 16, message: "The length of the password is between 6 and 16 bits", trigger: "blur" },
        ],
        checkPass: [
          { validator: this.validatePassword, trigger: 'blur' }
        ],
        code: [
          { required: true, message: "Please enter the verification code", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    // 打开窗口
    open() {
      this.type = "login";
      this.subForm = {
        username: "",
        email: "",
        password: "",
        checkPass: "",
        code: "",
        token: ""
      };
      this.visible = true;
    },
    // 关闭窗口
    close () {
      this.visible = false;
    },
    // 类型切换
    typeChange(e) {
      this.type = e;
      this.subForm = {
        username: "",
        email: "",
        password: "",
        checkPass: "",
        code: "",
        token: ""
      };
      this.countdown = 0;
      this.codeTimer = null;
      this.$refs.subForm.resetFields();
    },
    // 验证两次密码是否一致
    validatePassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('Please enter your password again'));
      } else if (value !== this.subForm.password) {
        callback(new Error('The password entered twice is inconsistent!'));
      } else {
        callback();
      }
    },
    // 登录系统操作
    async loginHandle() {
      this.loading = true;
      let param = {
        username: this.subForm.username,
        password: this.subForm.password,
      };
      let res = await loginSystem(param, true);
      if (res && res.code == 200) {
        if (res.data && res.data.token) {
          localStorage.setItem("cf40-token", res.data.token);
          this.$emit("success");
          this.close();
          this.$message.success("successful");
        }
      }
      this.loading = false;
    },
    // 注册系统操作
    async registerHandle() {
      this.loading = true;
      let param = {
        email: this.subForm.email,
        password: this.subForm.password,
        code: this.subForm.code,
      };
      let res = await registerSystem(param, true);
      if (res && res.code == 200) {
        if (res.data && res.data.token) {
          localStorage.setItem("cf40-token", res.data.token);
          this.$emit("success");
          this.close();
          this.$message.success("successful");
        }
      }
      this.loading = false;
    },
    // 重置密码操作
    async forgotHandle() {
      this.loading = true;
      let param = {
        password: this.subForm.password,
        code: this.subForm.code,
        token: this.subForm.token
      };
      let res = await forgotPassword(param, true);
      if (res && res.code == 200) {
        this.typeChange("login")
        this.$message.success(res.msg ? res.msg : "Reset password successful");
      }
      this.loading = false;
    },
    // 发送注册验证码
    async registerCode() {
      this.codeLoading = true;
      let param = {
        email: this.subForm.email
      };
      let _This = this;
      if (!_This.codeTimer) {
        let res = await sendRegisterCode(param, true);
        if (res && res.code == 200) {
          this.$message.success(res.msg ? res.msg : 'verification code sent successfully');
          _This.countdown = 60;
          _This.codeTimer = setInterval(() => {
            _This.countdown--;
            if (_This.countdown <= 0) {
              clearInterval(_This.codeTimer);
              _This.codeTimer = null;
            }
          }, 1000);
        }
      }
      this.codeLoading = false;
    },
    // 发送找回密码验证码
    async forgotCode() {
      this.codeLoading = true;
      let param = {
        email: this.subForm.email
      };
      let _This = this;
      if (!_This.codeTimer) {
        let res = await sendForgotCode(param, true);
        if (res && res.code == 200) {
          if (res.data && res.data.token) {
            this.subForm.token = res.data.token;
            this.$message.success(res.msg ? res.msg : 'verification code sent successfully');
            _This.countdown = 60;
            _This.codeTimer = setInterval(() => {
              _This.countdown--;
              if (_This.countdown <= 0) {
                clearInterval(_This.codeTimer);
                _This.codeTimer = null;
              }
            }, 1000);
          }
        }
      }
      this.codeLoading = false;
    },
    // 打开会员协议
    async openProtocol(name, key) {
      let _This = this;
      let res = await initConfig();
      if (res && res.code == 200) {
        if (res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            let item = res.data[i];
            if (item.config_key == key) {
              _This.$refs.serviceAgreementRef.open(name, item.config_value);
              break;
            }
          }
        }
      }
    },
    // 获取验证码
    getVeriCode() {
      this.$refs.subForm.validateField('email', (valid) => {
        if (!valid) {
          if (this.type == "register") {
            this.registerCode();
          } else if (this.type == "forgot") {
            this.forgotCode();
          }
        }
      });
    },
    // 执行操作
    operate() {
      this.$refs.subForm.validate((valid) => {
        if (valid) {
          if (this.type == 'login') {
            this.loginHandle();
          } else if (this.type == 'register') {
            this.registerHandle();
          } else if (this.type == 'forgot') {
            this.forgotHandle();
          }
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.login-english {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-logo {
    max-width: 280px;
    margin-left: 50px;
    margin-right: 20px;
  }
  &-form {
    flex: 1;
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 50px;
    .submit {
      width: 100%;
      padding: 10px 30px;
      font-size: 16px;
      font-family: Georgia_Bold;
      color: #ffffff;
      background-color: #ed6f33;
      &:hover {
        color: #ffffff;
        border-color: #fa996b;
        background-color: #fa996b;
      }
      &:active {
        border-color: #ed6f33;
        background-color: #ed6f33;
      }
    }
    .flex-row {
      display: flex;
      justify-content: space-between;
    }
    ::v-deep {
      .el-input__inner {
        &:focus {
          border-color: #ed6f33;
        }
      }
      .el-input-group__append {
        color: #ffffff;
        .el-button {
          border-color: #ed6f33;
          background-color: #ed6f33;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          &:hover, &:focus {
            border-color: #fa996b !important;
            background-color: #fa996b !important;
          }
          &:active {
            border-color: #ed6f33 !important;
            background-color: #ed6f33 !important;
          }
          &.is-disabled {
            border-color: #fa996b !important;
            background-color: #fa996b !important;
            &:hover {
              color: #ffffff;
            }
          }
        }
      }
    }
    .tip-accord {
      font-size: 12px;
      line-height: 18px;
      .text-link {
        cursor: pointer;
        color: #ed6f33;
      }
    }
    .forgot-pass, .register {
      cursor: pointer;
      user-select: none;
      font-family: Georgia_Bold;
      &:hover {
        color: #ed6f33;
      }
      &.no-link {
        cursor: auto;
        &:hover {
          color: inherit;
        }
      }
    }
    .verification-code {
      margin-left: 80px;
      &-header {
        &-title {
          cursor: pointer;
          padding: 20px;
          user-select: none;
          display: flex;
          &:hover {
            color: #ed6f33;
          }
        }
        &-tip {
          padding: 0 20px 20px;
        }
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .login-english {
    &-logo {
      max-width: 200px;
      margin-left: 20px;
      margin-right: 10px;
    }
    &-form {
      margin-right: 20px;
      margin-top: 20px;
      margin-bottom: 30px;
      .forgot-pass, .register {
        font-size: 12px;
      }
      .verification-code {
        margin-left: 30px;
        &-header {
          &-tip {
            padding: 0 0 20px 20px;
          }
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .login-english {
    flex-direction: column;
    &-logo {
      max-width: 230px;
      margin: 15px 0 0 0;
    }
    &-form {
      width: 100%;
      margin-right: 0;
      .verification-code {
        margin-left: 0;
        &-header {
          &-title {
            padding-left: 0;
          }
          &-tip {
            padding: 0;
            line-height: 25px;
          }
        }
      }
      ::v-deep {
        .el-form-item__label {
          float: none;
        }
        .el-form-item__content {
          margin-left: 0 !important;
        }
      }
    }
  }
}
</style>