<template>
  <div :class="{ 'single': true, 'en-font': type == 'en', 'cn-font': type == 'cn' }" v-loading="loading">
    <web-header :language="type"></web-header>
    <div class="single-wrap">
      <div :class="{ 'single-wrap-title': true, 'en-font-bold': type == 'en', 'cn-font-bold': type == 'cn' }">{{ info.title }}</div>
      <div class="single-wrap-content" v-html="info.content"></div>
    </div>
    <web-footer :language="type"></web-footer>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import { singleDetail } from "@/api";
export default {
  name: "Single",
  components: {
    WebHeader,
    WebFooter
  },
  data() {
    return {
      loading: false,
      info: {},
      type: "cn",
    };
  },
  created() {
    if (this.$route.params.id && this.isNumber(this.$route.params.id)) {
      if (this.$route.params.language && this.$route.params.language == "english") {
        this.type = "en";
      } else {
        this.type = "cn";
      }
      this.getDataInfo(this.$route.params.id);
    } else {
      this.$router.push('/404');
    }
  },
  methods: {
    // 获取用户信息
    async getDataInfo(msgId) {
      this.loading = true;
      let bool = this.type == "en" ? true : false;
      let res = await singleDetail({ id: msgId }, bool);
      if (res && res.code == 200) {
        this.info = res.data;
      }
      this.loading = false;
    }
  }
}
</script>
<style lang="scss" scoped>
.en-font {
  font-family: Georgia;
}
.en-font-bold {
  font-family: Georgia_Bold;
}
.cn-font {
  font-family: HarmonyOS_Regular;
}
.cn-font-bold {
  font-family: HarmonyOS_Bold;
}
.single {
  &-wrap {
    width: 960px;
    margin: 0 auto;
    &-title {
      margin-top: 120px;
      font-size: 40px;
      padding-bottom: 70px;
      border-bottom: 1px solid #cccccc;
    }
    &-content {
      min-height: 500px;
      margin-top: 50px;
      margin-bottom: 60px;
      img {
        max-width: 100%;
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .single {
    &-wrap {
      width: calc(100% - 60px);
      margin: 0 auto;
      &-title {
        margin-top: 50px;
        font-size: 30px;
        padding-bottom: 30px;
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .single {
    &-wrap {
      width: calc(100% - 60px);
      margin: 0 auto;
      &-title {
        margin-top: 40px;
        font-size: 28px;
        padding-bottom: 30px;
      }
      &-content {
        img {
          max-width: 100%;
        }
      }
    }
  }
}
</style>
