<template>
  <div class="equity">
    <web-header :language="type" ref="webHeaderRef"></web-header>
    <english-wrap @login="login" v-if="type == 'en'"></english-wrap>
    <chinese-wrap @login="login" v-else></chinese-wrap>
    <web-footer :language="type"></web-footer>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import ChineseWrap from "./component/chineseWrap";
import EnglishWrap from "./component/englishWrap";
import { payPalCheck } from "@/api";
export default {
  name: "Equity",
  components: {
    WebHeader,
    WebFooter,
    ChineseWrap,
    EnglishWrap
  },
  data() {
    return {
      type: 'cn'
    }
  },
  created() {
    if (this.$route.params.language && this.$route.params.language == "english") {
      this.type = "en";
    } else {
      this.type = "cn";
    }
    this.paySuccessTip();
  },
  methods: {
    // 登录
    login() {
      this.$refs.webHeaderRef.login();
    },
    // 检测支付是否成功
    async checkPayPal(token) {
      let lang = this.type == "en" ? "en" : "";
      let res = await payPalCheck({ orderId: token }, lang);
      if (res && res.code == 200) {
        if (res.msg) {
          this.$message.success(res.msg);
        } else {
          this.$message.success(lang ? "Payment successful" : "支付成功");
        }
      }
    },
    // PayPal支付成功提示语
    paySuccessTip() {
      if (this.$route.query.retracement && this.$route.query.retracement == 'success') {
        if (this.$route.query.token) {
          this.checkPayPal(this.$route.query.token);
        }
        if (this.type == 'en') {
          this.$alert(`<p style="font-family: Georgia;">
                                PayPal payment may be delayed. If your payment has been completed and there are no changes on the page, please wait for 1 minute before refreshing and trying again</p>`,
              `Reminder`, {
                dangerouslyUseHTMLString: true,
                confirmButtonText: 'Done',
                confirmButtonClass: "confirm-button",
                callback: (action) => {
                  if (action == "confirm") {
                    window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
                  }
                }
              });
        } else {
          this.$alert(`PayPal支付可能有延迟，如您支付已完成，页面无变化，请等待1分钟后刷新重试`, '温馨提示', {
            dangerouslyUseHTMLString: true,
            confirmButtonText: '确定',
            confirmButtonClass: "confirm-button",
            callback: (action) => {
              if (action == "confirm") {
                window.location.href = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
              }
            }
          });
        }
      }
    },
  }
}
</script>