import { render, staticRenderFns } from "./englishWrap.vue?vue&type=template&id=478e3ff6&scoped=true"
import script from "./englishWrap.vue?vue&type=script&lang=js"
export * from "./englishWrap.vue?vue&type=script&lang=js"
import style0 from "./englishWrap.vue?vue&type=style&index=0&id=478e3ff6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "478e3ff6",
  null
  
)

export default component.exports