export default {
    data () {
        return {
            swiperOptions: {
                autoplay: true,//可选选项，自动滑动
                // 如果需要分页器
                pagination: {
                    el: '.swiper-pagination',
                    clickable :true,
                },
            }
        }
    }
}